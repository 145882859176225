import logo from "./logo.svg";
import "./App.css";
import Header from "./Header/Header";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import Feature from "./Features/Feature";
import Home from "./Component/Home";
import ChallengesTabcard from "./TabCard/ChallengesTabcard";
import SolutionTabcard from "./TabCard/SoluctionTabcard";
import ValuepropTabcard from "./TabCard/ValurPropTabcard";
import BideaseWork from "./Component/BideaseWork";
import Friends from "./Friends/Friends";

// import Challenges from "./Component/Challenges";
// import Solution from "./Component/Solution";
// import TabCard from "./TabCard/TabCard";
import Footer from "./Footer/Footer";
import E2ETabcard from "./TabCard/E2ETabcard";
import Interested from "./Component/Interested";
import Challenges1 from "./Component/Challenges1";

function App() {
  return (
    <div className="App">
      <Home />
      <Feature />
      <ChallengesTabcard />
      <SolutionTabcard />
      <E2ETabcard/>
      <ValuepropTabcard/>
      <BideaseWork/>
      {/* <Interested/> */}
      <Friends/>
      {/* <Challenges1/> */}
      {/* <Challenges />
      <Solution></Solution>
      <TabCard/> */}
      <Footer/>
    </div>
  );
}

export default App;
