import React, { useState } from "react";
import tabone from "../assets/value_tab1.png";
import taboneactive from "../assets/value_tab1_active.png";
import tabtwo from "../assets/value_tab2.png";
import tabtwoactive from "../assets/value_tab2_active.png";
import tabthree from "../assets/value_tab3.png";
import tabthreeactive from "../assets/value_tab3_active.png";
import tabfour from "../assets/value_tab4.png";
import tabfouractive from "../assets/value_tab4_active.png";
import ValueProposition1 from "../Component/ValueProposition/ValueProposition1";
import ValueProposition2 from "../Component/ValueProposition/ValueProposition2";
import ValueProposition3 from "../Component/ValueProposition/ValueProposition3";
import ValueProposition4 from "../Component/ValueProposition/ValueProposition4";

const ValuepropTabcard = () => {
  const [openContant, setOpenContant] = useState("contantone");
  const [isActive, setIsActive] = useState(1);
  return (
    <>
      {openContant == "contantone" ? (
        <>
      <ValueProposition1/>      
        </>
      ) : openContant == "contanttwo" ? (
        <>
         <ValueProposition2/>
        </>
      ) : openContant == "contantthree" ? (
        <>
        <ValueProposition3/>
        </>
      ) : openContant == "contantfour" ? (
        <>
        <ValueProposition4/>
        </> ): null}
      <div
        className="container-fluid "
        style={{ backgroundColor: "#DDEDF0" ,fontFamily:'sans-serif'}}
      >
        <div className="row d-flex justify-content-center p-2">
          <div
            className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-start"
            onClick={() => {
              setOpenContant("contantone");
              setIsActive(1);
            }}
          >
            <span className="text-start">
              <img
                src={isActive === 1 ? taboneactive : tabone}
                className="img-fluid mx-1 mt-1"
                alt=""
                style={{maxWidth:'100px'}}
              />
            </span>
            <span
              className={
                isActive === 1
                  ? "d-flex align-items-center mt-3 ms-3 card Actcard"
                  : "d-flex align-items-center mt-3 ms-3"
              }
              style={{
                cursor: "pointer",
              }}
            >
              <p className="fw-normal h6">
              Cost Reduction in Logistics

              </p>
            </span>
          </div>
          <div
            className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-start"
            onClick={() => {
              setOpenContant("contanttwo");
              setIsActive(2);
            }}
          >
            <span className="text-start">
              <img
                src={isActive === 2 ? tabtwoactive : tabtwo}
                className="img-fluid mx-1 mt-1"
                alt=""
                style={{maxWidth:'100px'}}
              />
            </span>
            <span
              className={
                isActive === 2
                  ? "d-flex align-items-center mt-3 ms-3 card Actcard"
                  : "d-flex align-items-center mt-3 ms-3"
              }
              style={{
                cursor: "pointer",
              }}
            >
              <p className="fw-normal h6">
              Enhanced Operational Efficiency

              </p>
            </span>
          </div>
          <div
            className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-start"
            onClick={() => {
              setOpenContant("contantthree");
              setIsActive(3);
            }}
          >
            <span className="text-start">
              <img
                src={isActive === 3 ? tabthreeactive : tabthree}
                className="img-fluid mx-1 mt-1"
                alt=""
                style={{maxWidth:'100px'}}
              />
            </span>
            <span
              className={
                isActive === 3
                  ? "d-flex align-items-center mt-3 ms-3 card Actcard"
                  : "d-flex align-items-center mt-3 ms-3"
              }
              style={{
                cursor: "pointer",
              }}
            >
              <p className="fw-normal h6">
              Transparency and fast decision making

              </p>
            </span>
          </div>
          <div
            className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-start"
            onClick={() => {
              setOpenContant("contantfour");
              setIsActive(4);
            }}
          >
            <span className="text-start">
              <img
                src={isActive === 4 ? tabfouractive : tabfour}
                className="img-fluid mx-1 mt-1"
                alt=""
                style={{maxWidth:'100px'}}
              />
            </span>
            <span
              className={
                isActive === 4
                  ? "d-flex align-items-center mt-3 ms-3 card Actcard"
                  : "d-flex align-items-center mt-3 ms-3"
              }
              style={{
                cursor: "pointer",
              }}
            >
              <p className="fw-normal h6">
              Scalability, ESG and Compliance
              </p>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ValuepropTabcard;
