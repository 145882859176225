import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import backgroundImage from '../../assets/backgroundblur.jpeg';
function ValueProposition1() {
    const containerStyle = {
        background: `url(${backgroundImage})center/cover no-repeat`, // specify the background image properties
        fontFamily: 'sans-serif',
        // set a minimum height to cover the entire viewport
        // minHeight: '80vh',

    };
    return (
        <div style={containerStyle} className='d-flex flex-column justify-content-center align-items-center pt-3 container-fluid' >
            <div className=" ">
                <div className="row " data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" >
                    <div className='d-flex justify-content-center' id="valueproposition">
                        <div className="col-lg-10 ">
                            <h5 style={{ fontSize: '28px', fontWeight: 'bold' }} className="title text-center">Value Proposition </h5>
                            <p className="mt-2 text-center" style={{ fontSize: '15px', color: '#767676', textAlign: 'justify' }}>We deliver value to manufacturing logistics by Streamlining Processes, Cutting Costs,
                                Enhancing Reliability, Boosting Efficiency, and Leveraging Analytics.

                            </p>
                        </div>

                    </div>
                </div>
                <div className='d-flex align-items-center ' style={{ minHeight: '50vh' }}>
                    <Row className='d-flex justify-content-center align-items-center '>
                        <Col lg={5} sm={12} className='d-flex flex-column justify-content-center'>
                            <img src={require('../../assets/valuegraph1.png')} alt="cart" style={{ width: '100%' }}></img>
                        </Col>
                        <Col lg={5} sm={12} className='d-flex justify-content-center'>
                            <img src={require('../../assets/valuegraph2.png')} alt="cart" style={{ width: '100%' }}></img>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <div className="col-lg-10 ">
                    <p className="mt-2 text-center" style={{ fontSize: '15px', color: '#767676', textAlign: 'justify' }}>Up to 20% reduction in transportation costs through competitive bidding and optimized route planning.
                        15-25% decrease in logistics-related overhead by streamlining processes.

                    </p>
                </div>

            </div>

        </div>
    )
}

export default ValueProposition1