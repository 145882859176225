import React from 'react'
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";
import "./Header.css"
import { useState, useEffect } from "react";
function Header() {
  const [navbarText, setNavbarText] = useState("#fefefe");
  const [navbarBackground, setNavbarBackground] = useState('transparent');

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const navbarTransparent = scrollPosition < 50; // You can adjust this threshold as needed

      setNavbarBackground(navbarTransparent ? 'transparent' : 'linear-gradient(to right, #fefefe, #1AA4C8)');
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    
    // <div className='container'>
      <Navbar
        fixed="top"
        collapseOnSelect
        expand="lg"
        // style={{ transition: "background 0.5s ease",background:'linear-gradient(to right, #fefefe, #1AA4C8)' }}
          style={{
        transition: 'background 0.5s ease',
        background: navbarBackground,
      }}
      >
        <Container >

        <Navbar.Brand
          style={{ cursor: "pointer" }}
        >
          <img
            alt="Short logo"
            src={require('../assets/bideaselogo.png')}
            className="d-lg-inline-block d-none d-md-none d-sm-none align-top"
            style={{ width: "220px" }}
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto align-items-center ">
            <Nav.Link href="#feature" style={{ color: navbarText }}>
              Features
            </Nav.Link>
            <Nav.Link href="#challenges" style={{ color: navbarText }}>
              Challenges
            </Nav.Link>
            <Nav.Link href="#solution" style={{ color: navbarText }}>
              Solution
            </Nav.Link>
            <Nav.Link href="#e2eprocess" style={{ color: navbarText }}>
              E2E Process
            </Nav.Link>
            <Nav.Link href="#valueproposition" style={{ color: navbarText }}>
              Value Proposition
            </Nav.Link>

            <Nav.Link href="#" style={{ color: navbarText }}>
              Sign up for free
            </Nav.Link>
            <Nav.Link href="#">
              <>
                <Button
                  className="signin-button"
                  size="sm"
                  style={{ borderRadius: 20, minWidth: 100 }}
                  onClick={() => window.location.href = 'https://superadmin.bidease.radixforce.com/'}
                >
                  <div>
                    Login
                  </div>
                </Button>
              </>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>
    // </div>
  )
}

export default Header