import React, { useState } from "react";
import tabone from "../assets/tab1.png";
import taboneactive from "../assets/tab1active.png";
import tabtwo from "../assets/tab2.png";
import tabtwoactive from "../assets/tab2active.png";
import tabthree from "../assets/tab3.png";
import tabthreeactive from "../assets/tab3active.png";
import Challenges1 from "../Component/Challenges1";
import Challenges3 from "../Component/Challenges3";
import Challenges2 from "../Component/Challenges2";

const ChallengesTabcard = () => {
  const [openContant, setOpenContant] = useState("contantone");
  const [isActive, setIsActive] = useState(1);

  return (
    <>
    {/* <div className="transition-container" style={{ opacity: openContant !== 'none' ? 1 : 0, pointerEvents: openContant !== 'none' ? 'auto' : 'none' }}>
        {openContant === "contantone" && <Challenges1 />}
        {openContant === "contanttwo" && <Challenges2 />}
        {openContant === "contantthree" && <Challenges3 />}
      </div> */}
      {openContant == "contantone" ? (
        <>
       <Challenges1/>       
        </>
      ) : openContant == "contanttwo" ? (
        <>
          <Challenges3/>
        </>
      ) : openContant == "contantthree" ? (
        <>
          <Challenges2/>
        </>
      ) : null}
      <div
        className="container-fluid "
        style={{ backgroundColor: "#DDEDF0", fontFamily: 'sans-serif' }}
      >
        <div className="row d-flex justify-content-center p-2">
          <div
            className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-start"
            onClick={() => {
              setOpenContant("contantone");
              setIsActive(1);
            }}
          >
            <span className="text-start">
              <img
                src={isActive === 1 ? taboneactive : tabone}
                className="img-fluid mx-1 mt-1"
                alt=""
                style={{ maxWidth: '100px' }}
              />
            </span>
            <span
              className={
                isActive === 1
                  ? "d-flex align-items-center mt-3 ms-3 card Actcard"
                  : "d-flex align-items-center mt-3 ms-3"
              }
              style={{
                cursor: "pointer",
              }}
            >
              <p className="fw-normal h6">
                Transporter Onboarding and Price Negotiations
              </p>
            </span>
          </div>
          <div
            className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-start"
            onClick={() => {
              setOpenContant("contanttwo");
              setIsActive(2);
            }}
          >
            <span className="text-start">
              <img
                src={isActive === 2 ? tabtwoactive : tabtwo}
                className="img-fluid mx-1 mt-1"
                alt=""
                style={{ maxWidth: '100px' }}
              />
            </span>
            <span
              className={
                isActive === 2
                  ? "d-flex align-items-center mt-3 ms-3 card Actcard"
                  : "d-flex align-items-center mt-3 ms-3"
              }
              style={{
                cursor: "pointer",
              }}
            >
              <p className="fw-normal h6">
                Pick up Scheduling, Loading, and Dispatch
              </p>
            </span>
          </div>
          <div
            className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-start"
            onClick={() => {
              setOpenContant("contantthree");
              setIsActive(3);
            }}
          >
            <span className="text-start">
              <img
                src={isActive === 3 ? tabthreeactive : tabthree}
                className="img-fluid mx-1 mt-1"
                alt=""
                style={{ maxWidth: '100px' }}
              />
            </span>
            <span
              className={
                isActive === 3
                  ? "d-flex align-items-center mt-3 ms-3 card Actcard"
                  : "d-flex align-items-center mt-3 ms-3"
              }
              style={{
                cursor: "pointer",
              }}
            >
              <p className="fw-normal h6">
                Delivery Tracking, Invoicing, and Payments
              </p>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChallengesTabcard;
