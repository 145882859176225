import React from 'react'
import backgroundImage from '../assets/challangebackground.png';
import { Col, Row } from 'react-bootstrap'
import "./Home.css"
function Challenges1() {
    const containerStyle = {
        background: `url(${backgroundImage}) center/cover no-repeat`, // specify the background image properties
        fontFamily: 'sans-serif',
        // set a minimum height to cover the entire viewport
      

    };
    return (
        // <Container className='d-flex flex-column justify-content-center align-items-center background-img ' style={{fontFamily:'sans-serif'}} >
        <div style={containerStyle} className='d-flex flex-column justify-content-center align-items-center mt-4 pt-5 container-fluid' >
            <div className=" ">
                <div className="row " data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" >
                    <div className='d-flex justify-content-center' id="challenges">
                        <div className="col-lg-10 ">
                            <h5 style={{ fontSize: '28px', fontWeight: 'bold' }} className="title text-center">Challenges </h5>
                            <p className="mt-2 text-center" style={{ fontSize: '15px', color: '#767676', textAlign: 'justify' }}>There are several challenges that manufacturers face across various stages of the transportation from the initial<br></br>
                                engagement with transporters to the final delivery and payment for services rendered.
                            </p>
                        </div>
                        
                    </div>
                </div>
                <div className='d-flex align-items-center ' style={{minHeight:'50vh'}}>
                <Row className='d-flex justify-content-center align-items-center '>
                    <Col lg={5} sm={12} className='d-flex flex-column justify-content-center'>
                        <span style={{ fontSize: '18px',}}>Bidding Inefficiencies</span>
                        <p style={{ fontSize: '15px', color: '#767676', textAlign: 'justify' , }}> This includes problems with pricing negotiations and the overall inefficiencies in the bidding processes, which are directly related to transporter onboarding and establishing agreed-upon prices.</p>
                        <span style={{ fontSize: '18px',}}>Cost Fluctuation/Negotiation</span>
                        <p style={{ fontSize: '15px', color: '#767676', textAlign: 'justify' , }}>Addressing the issue of volatile transportation costs, which affects the ability to negotiate effectively with transporters.</p>
                        <span style={{ fontSize: '18px',}}>Carrier Selection</span>
                        <p style={{ fontSize: '15px', color: '#767676', textAlign: 'justify' , }}>  The difficulty in rating, screening, and selecting the right carriers is part of the onboarding process, which affects service quality and reliability.</p>
                       
                    </Col>
                    <Col lg={5} sm={12} className='d-flex justify-content-center'>
                    <img src={require('../assets/challange1.png')} alt="cart" style={{ width: '85%' }}></img>
                    </Col>
                </Row>
            </div>
            </div>
       
         </div> 
        // </Container>
    )
}

export default Challenges1