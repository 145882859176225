import React from 'react'
import { Col, Row } from 'react-bootstrap'
import backgroundImage from '../assets/backgroundimage.jpeg';
import gifUrl from '../assets/bidease.gif'
import Header from '../Header/Header';
function Home() {
  const containerStyle = {
    background: `url(${backgroundImage}) center/cover no-repeat`, // specify the background image properties
    fontFamily: 'sans-serif',
 
    // set a minimum height to cover the entire viewport
    // minHeight: '80vh',
    // justifyContent: 'center',
    // alignItems: 'center',
  };

  return (
    <>
      <div style={containerStyle} className='d-flex flex-column justify-content-center align-items-center container-fluid '>
        <Header/>
        <div className=" " style={{marginTop:'6%'}}>
          <div className='d-flex align-items-center '>
            <Row className='d-flex justify-content-center align-items-center '>
              <Col lg={5} sm={12} className='d-flex flex-column justify-content-center'>
                <h3 style={{ color: '#1C1F35', fontSize: '43px', fontWeight: 'bold' }}>Navigating Logistics.</h3>
                <h3 style={{ color: '#1C1F35', fontSize: '43px', fontWeight: 'bold' }}>Empowering Bids</h3>
                <p className="mt-5" style={{ color: "#545E63" }}>A Tailored Saas Approach to overcome Logistics Hurdles.<br></br>
                  reduce cost by upto 30% and drive cost efficieny of upto 50%</p>
                <p className="" style={{ color: "#545E63" }}>reduce cost by upto 30% and drive cost efficieny of upto 50%</p>
                <div style={{ marginTop: '50px' }}>
                <div className="col-lg-12 d-flex justify-content-start ">
                  <a href="#" className="align-self-center btn" style={{ background: 'linear-gradient(to right, #00D5AE, #1AA4C8)', color: 'white', fontSize: '14px', borderRadius: 20, marginRight: '10px' }}>
                    <span className='text-center'>Sign Up for free</span>
                  </a>
                  <a href="#" className="align-self-center btn" style={{ background: '#000', color: 'white', fontSize: '14px', borderRadius: 20 }}>
                    <span className='text-center'>Get a demo</span>
                  </a>
                </div>
              </div>
              </Col>
              <Col lg={5} sm={12} className='d-flex justify-content-center'>
                {/* <img src={require('../assets/challange.png')} alt="cart" style={{ width: '100%' }}></img> */}
              <img src={gifUrl} alt="Your GIF"  style={{ width: '100%' }}/>

              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* <div style={containerStyle}>
        <div className='d-flex justify-content-center ' style={{}} >
          <Row className='d-flex justify-content-center  col-10' style={{ marginTop: 150 }}>
            <Col sm={12} lg={6}>
              <h3 style={{ color: '#1C1F35', fontSize: '45px', fontWeight: 'bold' }}>Navigating Logistics.</h3>
              <h3 style={{ color: '#1C1F35', fontSize: '45px', fontWeight: 'bold' }}>Empowering Bids</h3>
              <p className="mt-5" style={{ color: "#545E63" }}>A Tailored Saas Approach to overcome Logistics Hurdles.<br></br>
                reduce cost by upto 30% and drive cost efficieny of upto 50%</p>
              <p className="" style={{color:"#545E63"}}>reduce cost by upto 30% and drive cost efficieny of upto 50%</p>
              <div style={{ marginTop: '50px' }}>
                <div className="col-lg-12 d-flex justify-content-start ">
                  <a href="#" className="align-self-center btn" style={{ background: 'linear-gradient(to right, #00D5AE, #1AA4C8)', color: 'white', fontSize: '14px', borderRadius: 20, marginRight: '10px' }}>
                    <span className='text-center'>Sign Up for free</span>
                  </a>
                  <a href="#" className="align-self-center btn" style={{ background: '#000', color: 'white', fontSize: '14px', borderRadius: 20 }}>
                    <span className='text-center'>Get a demo</span>
                  </a>
                </div>
              </div>
            </Col>
            <Col sm={12} lg={6} className='p-0 m-0' >
            
              <img src={gifUrl} alt="Your GIF" width="100%" height="100%"/>
            </Col>

          </Row>
        </div>
      </div> */}


    </>
  )
}

export default Home