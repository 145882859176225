import React, { useState } from "react";
import star from "../assets/Star 2.png";
import feature1 from "../assets/feature_icon1.png";
import feature2 from "../assets/feature_icon2.png";
import feature3 from "../assets/feature_icon3.png";
import featurelogo from "../assets/feature_logo.png";
import { Modal } from "react-bootstrap";
import backgroundImage from "../assets/backgroundblur.jpeg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Feature = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [showPopUptwo, setShowPopUptwo] = useState(false);
  const [showPopUpthree, setShowPopUpthree] = useState(false);
  const containerStyle = {
    background: `url(${backgroundImage}) center/cover no-repeat`, // specify the background image properties
    fontFamily: "sans-serif",
    // set a minimum height to cover the entire viewport
    // minHeight: "80vh",
    // justifyContent: "center",
    // alignItems: "center",
  };
  return (
    <>
      <div className="container-fluid" style={containerStyle}>
        <div className="row feature_scroll">
          <div
            className="col-12 d-flex justify-content-around align-items-center text-white mt-5"
            style={{ backgroundColor: "#1AA4C8" }}
          >
            <span className="d-flex justify-content-around align-items-center text-white p-3 pt-4 w-100">
              <h4>Navigating Logistics</h4>
              <img
                src={star}
                className="img-fluid mt-0"
                style={{ width: 16, height: 16 }}
                alt=""
              />
              <h4>Empowering Bids</h4>
              <img
                src={star}
                className="img-fluid mt-0"
                style={{ width: 16, height: 16 }}
                alt=""
              />
              <h4>Tracking</h4>
              <img
                src={star}
                className="img-fluid mt-0"
                style={{ width: 16, height: 16 }}
                alt=""
              />
              <h4>Delivery</h4>
              <img
                src={star}
                className="img-fluid mt-0"
                style={{ width: 16, height: 16 }}
                alt=""
              />
              <h4>Logistics</h4>
            </span>
          </div>
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center pt-3 '>
          <div>
            <div className="row mt-5" id="feature">
              <div className="d-flex justify-content-center">
                <div className="col-lg-10 ">
                  <h3 className="fw-semibold text-center">Features</h3>
                  <p className="text-secondary small text-center">
                    A Tailored SaaS Approach to Overcome Logistics Hurdles
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <Row className="d-flex justify-content-center align-items-center ">
                <Col
                  lg={5}
                  sm={12}
                  className="d-flex flex-column justify-content-center"
                >
                  <div className="container">
                    <div className="row mt-5">
                      <div className="col-lg-2 col-md-4 col-sm-12 text-center">
                        <img src={feature1} alt="" className="img-fluid" />
                      </div>
                      <div className="col-lg-10 col-md-8 col-sm-12">
                        <h5 className="fw-normal">
                          Comprehensive Bidding Platform
                        </h5>
                        <h6 className="text-secondary small w-75">
                          Fast-Track Transporter Onboarding with Guaranteed Best
                          Price Negotiations{" "}
                          <span
                            onClick={() => {
                              setShowPopUp(true);
                            }}
                            className="fw-bold"
                            style={{ color: "#1AA4C8", cursor: "pointer" }}
                          >
                            More...
                          </span>
                        </h6>
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-lg-2 col-md-4 col-sm-12 text-center">
                        <img src={feature2} alt="" className="img-fluid" />
                      </div>
                      <div className="col-lg-10 col-md-8 col-sm-12">
                        <h5 className="fw-normal">Seamless Loading Management</h5>
                        <h6 className="text-secondary small w-75">
                          Streamlining Pickup Scheduling, Loading, and Dispatch We
                          optimize pickup scheduling, loading, and dispatch
                          processes,
                          <span
                            onClick={() => {
                              setShowPopUptwo(true);
                            }}
                            className="fw-bold"
                            style={{ color: "#1AA4C8", cursor: "pointer" }}
                          >
                            More...
                          </span>
                        </h6>
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-lg-2 col-md-4 col-sm-12 text-center">
                        <img src={feature3} alt="" className="img-fluid" />
                      </div>
                      <div className="col-lg-10 col-md-8 col-sm-12">
                        <h5 className="fw-normal">
                          Continous Tracking and Analytics
                        </h5>
                        <h6 className="text-secondary small w-75">
                          Continuous Delivery Monitoring, Streamlining Invoice and
                          Payment Processing.{" "}
                          <span
                            onClick={() => {
                              setShowPopUpthree(true);
                            }}
                            className="fw-bold"
                            style={{ color: "#1AA4C8", cursor: "pointer" }}
                          >
                            More...
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={5} sm={12} className="d-flex justify-content-center">
                  <img
                    src={featurelogo}
                    alt="cart"
                    className="img-fluid"
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      {/* ===============modal popup one======================== */}
      <Modal
        show={showPopUp}
        onHide={() => {
          setShowPopUp(false);
        }}
        size="lg"
        scrollable
        style={{ marginTop: "100px" }}
      >
        <Modal.Header closeButton style={{ backgroundColor: "#1AA4C8",fontFamily:'sans-serif' }}>
          <Modal.Title className="text-white" >
            Comprehensive Bidding Platform
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-3 mb-3" style={{fontFamily:'sans-serif'}}>
            <div className="col mx-4">
              <h5>
                Fast-Track Transporter Onboarding with Guaranteed Best Price
                Negotiations
              </h5>
              <ul className="mt-4">
                <li>
                  We offer a cutting-edge, transparent bidding platform that
                  revolutionizes pricing negotiations, ensuring fair and
                  competitive pricing.
                </li>
                <li className="mt-3">
                  Our sophisticated cost management tools adeptly tackle
                  transportation expenses, leading to more efficient and
                  strategic carrier negotiations.
                </li>
                <li className="mt-3">
                  {" "}
                  Our system implements rigorous quality assurance mechanisms,
                  enhancing the carrier selection process to guarantee superior
                  service quality and reliability.
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* ===============modal popup one======================== */}
      {/* ===============modal popup two======================== */}
      <Modal
        show={showPopUptwo}
        onHide={() => {
          setShowPopUptwo(false);
        }}
        size="lg"
        scrollable
        style={{ marginTop: "100px" }}
      >
        <Modal.Header closeButton style={{ backgroundColor: "#1AA4C8",fontFamily:'sans-serif' }}>
          <Modal.Title className="text-white">
            Seamless Loading Management
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-3 mb-3" style={{fontFamily:'sans-serif'}}>
            <div className="col mx-4">
              <h5>Streamlining Pickup Scheduling, Loading, and Dispatch</h5>
              <ul className="mt-4">
                <li>
                  We optimize pickup scheduling, loading, and dispatch
                  processes, significantly reducing delays and enhancing overall
                  efficiency.
                </li>
                <li className="mt-3">
                  Our robust security measures provide an unparalleled safeguard
                  against collusion, theft, and damage, ensuring the secure
                  delivery of goods.
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* ===============modal popup two======================== */}
      {/* ===============modal popup three======================== */}
      <Modal
        show={showPopUpthree}
        onHide={() => {
          setShowPopUpthree(false);
        }}
        size="lg"
        scrollable
        style={{ marginTop: "100px" }}
      >
        <Modal.Header closeButton style={{ backgroundColor: "#1AA4C8",fontFamily:'sans-serif' }}>
          <Modal.Title className="text-white">
            Continous Tracking and Analytics
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-3 mb-3" style={{fontFamily:'sans-serif'}}>
            <div className="col mx-4">
              <h5>
                Continuous Delivery Monitoring, Streamlining Invoice and Payment
                Processing.
              </h5>
              <ul className="mt-4">
                <li>
                  Our comprehensive tracking system offers unprecedented
                  visibility and control, ensuring seamless tracking throughout
                  the shipment journey.
                </li>
                <li className="mt-3">
                  With our automated data management solution, we eliminate the
                  inefficiencies of manual methods, ensuring accuracy and
                  efficiency in data handling.
                </li>
                <li className="mt-3">
                  The seamless invoicing and payment system we provide
                  streamlines financial transactions, ensuring timely payments
                  and alleviating operational delays.
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* ===============modal popup three======================== */}
    </>
  );
};

export default Feature;
