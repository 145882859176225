import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import backgroundImage from '../assets/backgroundblur.jpeg';
function Solution2() {
    const containerStyle = {
        background: `url(${backgroundImage})center/cover no-repeat`, // specify the background image properties
        fontFamily: 'sans-serif',
        // set a minimum height to cover the entire viewport
        // minHeight: '80vh',

    };
    return (
        // </Container>
        <div style={containerStyle} className='d-flex flex-column justify-content-center align-items-center pt-3 container-fluid'>
            <div className=" ">
                {/* <div className="row " data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" >
                    <div className='d-flex justify-content-center' id="solution">
                        <div className="col-lg-10 ">
                            <h5 style={{ fontSize: '28px', fontWeight: 'bold' }} className="title text-center">Solution 
                            <img src={require('../assets/solutionbidlogo.png')} alt="cart" style={{ width: '10%' }}></img></h5>
                            <p className="mt-2 text-center" style={{ fontSize: '15px', color: '#767676', textAlign: 'justify' }}>
                            A Tailored SaaS Approach to Overcome Logistics Hurdles, reduce cost by up to 30% and drive cost efficiency of up to 50%.
                            </p>
                        </div>

                    </div>
                </div> */}
                <div className='d-flex align-items-center ' style={{minHeight:'50vh'}}>
                    <Row className='d-flex justify-content-center align-items-center '>
                    <Col lg={5} sm={12} className='d-flex justify-content-center'>
                            <img src={require('../assets/solution2.png')} alt="cart" style={{ width: '85%' }}></img>
                        </Col>
                        <Col lg={6} sm={12} className='d-flex flex-column justify-content-center'>
                            <span style={{ fontSize: '18px', }}>Pickup and Loading:</span>
                            <p style={{ fontSize: '15px', color: '#767676', textAlign: 'justify', }}>We optimize pickup scheduling, loading, and dispatch processes, significantly reducing delays and enhancing overall efficiency.</p>
                            <span style={{ fontSize: '18px', }}>Quality and Audits:</span>
                            <p style={{ fontSize: '15px', color: '#767676', textAlign: 'justify', }}>Our robust security measures provide an unparalleled safeguard against collusion, theft, and damage, ensuring the secure delivery of goods.</p>
                           
                        </Col>
                        
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Solution2