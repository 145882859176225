import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import backgroundImage from '../../assets/backgroundblur.jpeg';
function ValueProposition3() {
    const containerStyle = {
        background: `url(${backgroundImage})center/cover no-repeat`, // specify the background image properties
        fontFamily: 'sans-serif',
        // set a minimum height to cover the entire viewport
        // minHeight: '80vh',

    };
    return (
        <div style={containerStyle} className='d-flex flex-column justify-content-center align-items-center pt-3 container-fluid' >
            <div className=" ">
                <div className="row " data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" >
                    <div className='d-flex justify-content-center' id="valueproposition">
                        <div className="col-lg-10 ">
                            <h5 style={{ fontSize: '28px', fontWeight: 'bold' }} className="title text-center">Value Proposition </h5>
                            <p className="mt-2 text-center" style={{ fontSize: '15px', color: '#767676', textAlign: 'justify' }}>We deliver value to manufacturing logistics by Streamlining Processes, Cutting Costs,
                                Enhancing Reliability, Boosting Efficiency, and Leveraging Analytics.

                            </p>
                        </div>

                    </div>
                </div>
                <div className='d-flex align-items-center ' style={{ minHeight: '50vh' }}>
                    <Row className='d-flex justify-content-center align-items-center '>
                        <Col lg={5} sm={12} className='d-flex flex-column justify-content-center'>
                            <span style={{ fontSize: '18px', }}>Increased Transparency and Accountability:</span>
                            <p style={{ fontSize: '15px', color: '#767676', textAlign: 'justify', }}> There is lack of real-time visibility and control in shipment tracking is unmanaged and unpredictable.</p>
                            <span style={{ fontSize: '18px', }}>Improved Financial Management:</span>
                            <p style={{ fontSize: '15px', color: '#767676', textAlign: 'justify', }}> Its is a nightmare to track delivery quality and customers are always upset and complaining.</p>
                            <span style={{ fontSize: '18px', }}>Data-Driven Decision Making:</span>
                            <p style={{ fontSize: '15px', color: '#767676', textAlign: 'justify', }}>  The truck companies are always struggling to Streamlining the invoicing and payment process that affects both the end of the delivery process and the financial closure of the transaction.</p>
                            <span style={{ fontSize: '18px', }}>Customer Satisfaction and Service Quality:</span>
                            <p style={{ fontSize: '15px', color: '#767676', textAlign: 'justify', }}>  There is huge reliance on manual methods for data management can hamper efficient delivery tracking, invoicing, and payments, and thus digital solutions could be implemented to mitigate this issue.</p>
                        </Col>
                        <Col lg={5} sm={12} className='d-flex justify-content-center'>
                            <img src={require('../../assets/valueprop3.png')} alt="cart" style={{ width: '85%' }}></img>
                        </Col>
                    </Row>
                </div>
            </div>

        </div>
    )
}

export default ValueProposition3
