import React from 'react'
import backgroundImage from '../assets/backgroundblur.jpeg';
import { Col, Row, Container } from 'react-bootstrap'
import gifUrl from '../assets/bidease.gif'
function BideaseWork() {
    const containerStyle = {
        background: `url(${backgroundImage})center/cover no-repeat`, // specify the background image properties
        fontFamily: 'sans-serif',
        // set a minimum height to cover the entire viewport
        // minHeight: '80vh',

    };
    return (
        <div style={containerStyle} className='d-flex flex-column justify-content-center align-items-center pt-5 container-fluid '>
            <div className=" ">
                <div className='d-flex align-items-center '>
                    <Row className='d-flex justify-content-center align-items-center '>
                        <Col lg={4} sm={12} className='d-flex flex-column justify-content-center'>
                            <h5 style={{ fontSize: '23px', fontWeight: 'bold', textAlign: 'justify', }} >How BidEase Works </h5>
                            <p style={{ fontSize: '15px', color: '#767676', textAlign: 'justify', }}> Consumers are missing key information at the time of decision making. <br></br>BidEase offers a web-based app with monthly subscriptions. 
                            Featuring<br></br> planners that represent past, present, and future transactions for a <br></br>collection of choices. It also simplifies data entry through a chat <br></br>feature making it significantly faster to evaluate hypothetical scenarios.</p>
                            <div className="col-lg-12 d-flex justify-content-center ">
                                <a href="#" className="align-self-center btn" style={{ background: '#000', color: 'white', fontSize: '14px', borderRadius: 10, marginRight: '10px' }}>
                                    <span className='text-center'>Subscribe</span>
                                </a>
                            </div>
                        </Col>
                        <Col lg={5} sm={12} className='d-flex justify-content-center'>
                        <img src={gifUrl} alt="Your GIF"  style={{ width: '80%' }}/>

                        </Col>
                    </Row>
                </div>
            </div>
            {/* intrested section */}
            <div className="row pt-5" data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                    <div className='d-flex justify-content-center'>
                        <div className="col-lg-10 ">
                            <h5 style={{ fontSize: '28px', fontWeight: 'bold' }} className="title text-center">Interested? </h5>
                            <p className="mt-2 text-center" style={{ fontSize: '15px', color: '#767676', textAlign: 'justify' }}>Sign up and take better control of your bids
                            </p>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center '>
                    <Row className='d-flex justify-content-center align-items-center '>
                        {/* <Col lg={5} sm={12} className='d-flex flex-column justify-content-center'>
                            <img src={require('../assets/interestman.png')} alt="cart" style={{ width: '100%' }}></img>
                        </Col> */}
                        <Col className="col-sm-12 col-lg-5 pt-3 mb-3 d-flex justify-content-center">
                            <span className="">
                                <img src={require('../assets/interestman.png')} alt="" className="img-fluid" />
                            </span>
                        </Col>
                        <Col lg={7} sm={12} className='d-flex flex-column justify-content-center'>
                            <form>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <div className="form-group">
                                            <input type="text" className="form-control" id="name" placeholder="Enter your name" />
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6}className=''>
                                        <div className="form-group mr-">
                                            <input type="email" className="form-control" id="email" placeholder="Enter your email address" />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="form-group mt-3">
                                    <textarea className="form-control" id="additionalInfo" rows="3" placeholder="What would you like Bidease to do for you?"></textarea>
                                </div>
                                <div className="col-lg-12 d-flex justify-content-center mt-4 ">
                                    <a href="#" className="align-self-center btn " style={{ background: '#000', color: 'white', fontSize: '14px', borderRadius: 10, marginRight: '10px' }}>
                                        <span className='text-center p-4'>Sign Up</span>
                                    </a>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </div>
                
        </div>
    )
}

export default BideaseWork
