import React from 'react'
import backgroundImage from '../assets/challangebackground.png';
import { Col, Row } from 'react-bootstrap'
import "./Home.css"
function Challenges3() {
    const containerStyle = {
        background: `url(${backgroundImage}) center/cover no-repeat`, // specify the background image properties
        fontFamily: 'sans-serif',
        // set a minimum height to cover the entire viewport
        // minHeight: '80vh',
      

    };
    return (
        // <Container className='d-flex flex-column justify-content-center align-items-center background-img ' style={{fontFamily:'sans-serif'}} >
        <div style={containerStyle} className='d-flex flex-column justify-content-center align-items-center mt-4 pt-5 container-fluid' >
            <div className=" ">
                <div className="row " data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" >
                    <div className='d-flex justify-content-center' id="challenges">
                        <div className="col-lg-10 ">
                            <h5 style={{ fontSize: '28px', fontWeight: 'bold' }} className="title text-center">Challenges </h5>
                            <p className="mt-2 text-center" style={{ fontSize: '15px', color: '#767676', textAlign: 'justify' }}>There are several challenges that manufacturers face across various stages of the transportation from the initial<br></br>
                                engagement with transporters to the final delivery and payment for services rendered.
                            </p>
                        </div>
                        
                    </div>
                </div>
                <div className='d-flex align-items-center '  style={{minHeight:'50vh'}}>
                <Row className='d-flex justify-content-center align-items-center '>
                    <Col lg={5} sm={12} className='d-flex flex-column justify-content-center'>
                        <span style={{ fontSize: '18px',}}>Visibility Constraints:</span>
                        <p style={{ fontSize: '15px', color: '#767676', textAlign: 'justify' , }}> There is lack of real-time visibility and control in shipment tracking is unmanaged and unpredictable.</p>
                        <span style={{ fontSize: '18px',}}>Delivery Quality:</span>
                        <p style={{ fontSize: '15px', color: '#767676', textAlign: 'justify' , }}> Its is a nightmare to track delivery quality and customers are always upset and complaining.</p>
                        <span style={{ fontSize: '18px',}}>Invoice & Payment:</span>
                        <p style={{ fontSize: '15px', color: '#767676', textAlign: 'justify' , }}>  The truck companies are always struggling to Streamlining the invoicing and payment process that affects both the end of the delivery process and the financial closure of the transaction.</p>
                        <span style={{ fontSize: '18px',}}>Manual Methods:</span>
                        <p style={{ fontSize: '15px', color: '#767676', textAlign: 'justify' , }}>  There is huge reliance on manual methods for data management can hamper efficient delivery tracking, invoicing, and payments, and thus digital solutions could be implemented to mitigate this issue.</p>
                    </Col>
                    <Col lg={5} sm={12} className='d-flex justify-content-center'>
                    <img src={require('../assets/challange.png')} alt="cart" style={{ width: '85%' }}></img>
                    </Col>
                </Row>
            </div>
            </div>
       
         </div> 
        // </Container>
    )
}

export default Challenges3