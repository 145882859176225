import React, { useState } from "react";
import tabone from "../assets/e2e_tab1.png";
import taboneactive from "../assets/e2e_tab1_active.png";
import tabtwo from "../assets/e2e_tab2.png";
import tabtwoactive from "../assets/e2e_tab2_active.png";
import tabthree from "../assets/e2e_tab3.png";
import tabthreeactive from "../assets/e2e_tab3_active.png";
import E2Eprocess1 from "../Component/E2Eprocess/E2Eprocess1";
import E2Eprocess2 from "../Component/E2Eprocess/E2Eprocess2";
import E2Eprocess3 from "../Component/E2Eprocess/E2Eprocess3";

const E2ETabcard = () => {
    const [openContant, setOpenContant] = useState("contantone");
    const [isActive, setIsActive] = useState(1);
    return (
        <>
            {openContant == "contantone" ? (
                <>
                    <E2Eprocess1 />
                </>
            ) : openContant == "contanttwo" ? (
                <>
                    <E2Eprocess2 />
                </>
            ) : openContant == "contantthree" ? (
                <>
                    <E2Eprocess3 />
                </>
            ) : null}
            <div
                className="container-fluid "
                style={{ backgroundColor: "#DDEDF0", fontFamily: 'sans-serif' }}
            >
                <div className="row d-flex justify-content-center p-2">
                    <div
                        className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-start"
                        onClick={() => {
                            setOpenContant("contantone");
                            setIsActive(1);
                        }}
                    >
                        <span className="text-start">
                            <img
                                src={isActive === 1 ? taboneactive : tabone}
                                className="img-fluid mx-1 mt-1"
                                alt=""
                                style={{maxWidth:'100px'}}
                            />
                        </span>
                        <span
                            className={
                                isActive === 1
                                    ? "d-flex align-items-center mt-3 ms-3 card Actcard"
                                    : "d-flex align-items-center mt-3 ms-3"
                            }
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            <p className="fw-normal h6">
                                Streamlining Logistics
                                Digital Onboarding & Bidding Process

                            </p>
                        </span>
                    </div>
                    <div
                        className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-start"
                        onClick={() => {
                            setOpenContant("contanttwo");
                            setIsActive(2);
                        }}
                    >
                        <span className="text-start">
                            <img
                                src={isActive === 2 ? tabtwoactive : tabtwo}
                                className="img-fluid mx-1 mt-1"
                                alt=""
                                style={{maxWidth:'100px'}}
                            />
                        </span>
                        <span
                            className={
                                isActive === 2
                                    ? "d-flex align-items-center mt-3 ms-3 card Actcard"
                                    : "d-flex align-items-center mt-3 ms-3"
                            }
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            <p className="fw-normal h6">
                                Efficient Logistics
                                The Journey of Loading Goods

                            </p>
                        </span>
                    </div>
                    <div
                        className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-start"
                        onClick={() => {
                            setOpenContant("contantthree");
                            setIsActive(3);
                        }}
                    >
                        <span className="text-start">
                            <img
                                src={isActive === 3 ? tabthreeactive : tabthree}
                                className="img-fluid mx-1 mt-1"
                                alt=""
                                style={{maxWidth:'100px'}}
                            />
                        </span>
                        <span
                            className={
                                isActive === 3
                                    ? "d-flex align-items-center mt-3 ms-3 card Actcard"
                                    : "d-flex align-items-center mt-3 ms-3"
                            }
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            <p className="fw-normal h6">
                                Advance Logistics Management
                                Tracking, Delivery, & Payment

                            </p>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default E2ETabcard;
