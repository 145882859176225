import React from "react";
import fb from "../assets/facebook1.png";
import linkd from "../assets/linkedin1.png";
import twitt from "../assets/twitter1.png";
import insta from "../assets/insta1.png";
import friend from "../assets/friend_img.png";
import backgroundImage from "../assets/bg_friend1.png";

const Friends = () => {
  const containerStyle = {
    background: `url(${backgroundImage}) center/cover no-repeat`,
    fontFamily: "sans-serif",
    minHeight: "70vh",
    // justifyContent: "center",
    // alignItems: "center",
  };
  return (
    <>
      <div style={containerStyle}>
        <div className="container mb-0 pt-5">
          <div className="row align-items-center">
            <div className="col-sm-12 col-lg-7 mb-3">
              <span className="text-black d-flex flex-column justify-content-center align-items-center">
                <span className="pt-3 pb-4 text-center">
                  <h2 className="fw-semibold pb-2">Tell Your Friends! </h2>
                  <small className="text-secondary fw-normal fs-6">
                    Share the benefits of effortless cash flow management with<br/>
                    BidEase by telling your friends today!
                  </small>
                </span>
                <span className="text-center align-items-center">
                  <img alt="" src={fb} className="img-fluid m-2" />
                  <img alt="" src={linkd} className="img-fluid m-2" />
                  <img alt="" src={twitt} className="img-fluid m-2" />
                  <img alt="" src={insta} className="img-fluid m-2" />
                </span>
              </span>
            </div>
            <div className="col-sm-12 col-lg-5 pt-3 mb-3 d-flex justify-content-center">
              <span className="">
                <img src={friend} alt="" className="img-fluid" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Friends;
