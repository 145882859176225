import React from "react";
import logo from "../assets/footer_logo.png";
import one from "../assets/1.svg";
import two from "../assets/2.svg";
import three from "../assets/3.svg";
import four from "../assets/4.svg";
import mail from "../assets/mail.svg";
import call from "../assets/call.svg";
import location from "../assets/location.svg";

const Footer = () => {
  return (
    <>
      <div>
        <div className="pt-5" style={{ backgroundColor: "#1C2752",fontFamily:'sans-serif'}}>
          <div className="container">
            <div className="container mb-0 ">
              <div className="row justify-content-lg-between align-items-start">
                <div className="col-6 col-sm-4 col-lg-auto pt-2 mb-3 sm_scr">
                  <img src={logo} alt="" className="img-fluid" />
                </div>
                <div className="col-6 col-sm-4 col-lg-auto mb-3">
                  <sapn className="row text-white">
                    <span className="col-sm-12 col-lg-4">
                      <img alt="" src={mail} className="img-fluid" />
                    </span>
                    <span className="col-sm-12 col-lg-8 text-start align-items-center pt-2 ps-2">
                      <span className="fw-semibold">Mail Us </span>
                      <br />
                      <span>info@bidease.in</span>
                    </span>
                  </sapn>
                </div>
                <div className="col-6 col-sm-4 col-lg-auto mb-3">
                  <sapn className="row text-white">
                    <span className="col-sm-12 col-lg-4">
                      <img alt="" src={call} className="img-fluid" />
                    </span>
                    <span className="col-sm-12 col-lg-8 text-start align-items-center pt-2 ps-2">
                      <span className="fw-semibold">Call Us </span>
                      <br />
                      <span>+91 89898 99596</span>
                    </span>
                  </sapn>
                </div>
                <div className="col-6 col-sm-4 col-lg-auto mb-3">
                  <sapn className="row text-white">
                    <span className="col-sm-12 col-lg-4">
                      <img alt="" src={location} className="img-fluid" />
                    </span>
                    <span className="col-sm-12 col-lg-8 text-start align-items-center pt-2 ps-2">
                      <span className="fw-semibold">Location </span>
                      <br />
                      <span>Indore, 452016</span>
                    </span>
                  </sapn>
                </div>
              </div>
            </div>
            <hr className="text-white" />
            <div className="container">
              <div className="row justify-content-lg-around">
                <div className="col-6 col-sm-4 col-lg-auto pt-2 text-start">
                  <h6 className="fw-normal text-white" style={{fontFamily:''}}>
                  Transforming manufacturing logistics with rapid onboarding,<br></br> cost-effective negotiations, secure transport,real-time tracking, <br></br> and data-driven decision-making for optimal efficiency.
                  </h6>
                  <div className="logo_size">
                    <img src={one} alt="" className="img-fluid m-2" />
                    <img src={two} alt="" className="img-fluid m-2" />
                    <img src={three} alt="" className="img-fluid m-2" />
                    <img src={four} alt="" className="img-fluid m-2" />
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-lg-auto">
                  <h5 className="fw-bold pb-3 text-white">Explore</h5>
                  <ul className="list-unstyled mb-md-4 mb-lg-0 text-white">
                    <li className="mb-2">Features</li>
                    <li className="mb-2">Challenges</li>
                    <li className="mb-2 ">Solution</li>
                    <li className="mb-2 ">E2E Process</li>
                    <li className="mb-2 ">Value Proposition</li>
                  </ul>
                </div>
                <div className="col-6 col-sm-4 col-lg-auto">
                  <h5 className="fw-bold pb-3 text-white">Quick Links</h5>
                  <ul className="list-unstyled mb-md-4 mb-lg-0 text-white">
                    <li className="mb-2">Services</li>
                    <li className="mb-2">Blog</li>
                    <li className="mb-2 ">Contact Us</li>
                  </ul>
                </div>
              </div>
            </div>
            <hr className="text-white m-0 p-0" />
            <div className="container text-white">
              <div className="row">
                <div className="col text-center py-3 fw-semibold">
                  <small>Copyright ©BidEase</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
