import React, { useState } from "react";
import tabone from "../assets/solution_tab1.png";
import taboneactive from "../assets/solution_tab1_active.png";
import tabtwo from "../assets/solution_tab2.png";
import tabtwoactive from "../assets/solution_tab2_active.png";
import tabthree from "../assets/solution_tab3.png";
import tabthreeactive from "../assets/solution_tab3_active.png";
import Solution3 from "../Component/Solution3";
import Solution1 from "../Component/Solution1";
import Solution2 from "../Component/Solution2";
import backgroundImage from "../assets/backgroundblur.jpeg"
const SolutionTabcard = () => {
  const [openContant, setOpenContant] = useState("contantone");
  const [isActive, setIsActive] = useState(1);
  const containerStyle = {
    background: `url(${backgroundImage})center/cover no-repeat`, // specify the background image properties
    fontFamily: 'sans-serif',
    // set a minimum height to cover the entire viewport
    // minHeight: '80vh',

  };
  return (
    <>
      <div style={containerStyle} className='d-flex flex-column justify-content-center align-items-center pt-3 container-fluid'>
        <div className="row " data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" >
          <div className='d-flex justify-content-center' id="solution">
            <div className="col-lg-10 ">
              <h5 style={{ fontSize: '28px', fontWeight: 'bold' }} className="title text-center">Solution
                <img src={require('../assets/solutionbidlogo.png')} alt="cart" style={{ width: '10%' }}></img></h5>
              <p className="mt-2 text-center" style={{ fontSize: '15px', color: '#767676', textAlign: 'justify' }}>
                A Tailored SaaS Approach to Overcome Logistics Hurdles, reduce cost by up to 30% and drive cost efficiency of up to 50%.
              </p>
            </div>

          </div>
        </div>
        {openContant == "contantone" ? (
          <>
            <Solution1 />
          </>
        ) : openContant == "contanttwo" ? (
          <>
            <Solution3 />
          </>
        ) : openContant == "contantthree" ? (
          <>
            <Solution2 />
          </>
        ) : null}
        <div
          className="container-fluid "
          style={{ backgroundColor: "#DDEDF0", fontFamily: "sans-serif" }}
        >
          <div className="row d-flex justify-content-center p-2">
            <div
              className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-start"
              onClick={() => {
                setOpenContant("contantone");
                setIsActive(1);
              }}
            >
              <span className="text-start">
                <img
                  src={isActive === 1 ? taboneactive : tabone}
                  className="img-fluid mx-1 mt-1"
                  alt=""
                  style={{ maxWidth: '100px' }}
                />
              </span>
              <span
                className={
                  isActive === 1
                    ? "d-flex align-items-center mt-3 ms-3 card Actcard"
                    : "d-flex align-items-center mt-3 ms-3"
                }
                style={{
                  cursor: "pointer",
                }}
              >
                <p className="fw-normal h6">
                  Fast-Track Transporter Onboarding With Guarantee Best Price
                  Negotiations
                </p>
              </span>
            </div>
            <div
              className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-start"
              onClick={() => {
                setOpenContant("contanttwo");
                setIsActive(2);
              }}
            >
              <span className="text-start">
                <img
                  src={isActive === 2 ? tabtwoactive : tabtwo}
                  className="img-fluid mx-1 mt-1"
                  alt=""
                  style={{ maxWidth: '100px' }}
                />
              </span>
              <span
                className={
                  isActive === 2
                    ? "d-flex align-items-center mt-3 ms-3 card Actcard"
                    : "d-flex align-items-center mt-3 ms-3"
                }
                style={{
                  cursor: "pointer",
                }}
              >
                <p className="fw-normal h6">
                  Streamlining Pickup Scheduling, Loading, and Dispatch
                </p>
              </span>
            </div>
            <div
              className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-start"
              onClick={() => {
                setOpenContant("contantthree");
                setIsActive(3);
              }}
            >
              <span className="text-start">
                <img
                  src={isActive === 3 ? tabthreeactive : tabthree}
                  className="img-fluid mx-1 mt-1"
                  alt=""
                  style={{ maxWidth: '100px' }}
                />
              </span>
              <span
                className={
                  isActive === 3
                    ? "d-flex align-items-center mt-3 ms-3 card Actcard"
                    : "d-flex align-items-center mt-3 ms-3"
                }
                style={{
                  cursor: "pointer",
                }}
              >
                <p className="fw-normal h6">
                  Continuous Delivery Monitoring, Streamlining Invoice and Payment
                  Processing.
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SolutionTabcard;
